<template>
  <div class="tool-bar" :class="{ 'iphonex-bottom': isIphoneX }">
    <div
      class="tool-bar-item"
      @click="onItemClick(item)"
      v-for="(item, index) in toolBarData"
      :key="index"
    >
      <img
        class="tool-bar-item-img"
        :src="[item.componentName === selectIndex ? item.hIcon : item.nIcon]"
      />
      <p
        class="tool-bar-item-name"
        :class="{ 'tool-bar-item-name-h': item.componentName === selectIndex }"
      >
        {{ item.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolBar",
  data() {
    return {
      toolBarData: [
        {
          nIcon: require("@image/if-n.png"),
          hIcon: require("@image/if-h.png"),
          name: "信息库",
          componentName: "Informationbase",
        },
        {
          nIcon: require("@image/mall-n.png"),
          hIcon: require("@image/mall-h.png"),
          name: "服务商城",
          componentName: "ServiceMall",
        },
        {
          nIcon: require("@image/nw-n.png"),
          hIcon: require("@image/nw-h.png"),
          name: "智能网盘",
          componentName: "Network",
        },
        {
          nIcon: require("@image/cm-n.png"),
          hIcon: require("@image/cm-h.png"),
          name: "社区",
          componentName: "Community2",
        },
        {
          nIcon: require("@image/my-n.png"),
          hIcon: require("@image/my-h.png"),
          name: "我的",
          componentName: "My",
        },
      ],
      selectIndex: "Informationbase",
      isIphoneX: window.isIphoneX,
      userInfo:''
    };
  },
  computed : {
    //iPhone
    isiPhone(){
      return /iPhone|iPad/i.test(navigator.userAgent);
    },
  },
  watch: {
    $route: {
      handler: "getBreadcrumb",
      deep: true,
      immediate: true,
      // if you go to the redirect page, do not update the breadcrumbs
      // if (route.path.startsWith("/redirect/")) {
      //   return;
      // }
      // console.log(1213);
    },
  },
  methods: {
    getBreadcrumb() {
      //
      if(/iphone|ipad/i.test(navigator.userAgent) && this.$route.matched[0].name == "ServiceMallDef"){
        this.selectIndex = "ServiceMall";
      }else if(/iphone|ipad/i.test(navigator.userAgent) && this.$route.matched[0].name == "VipBuy"){
        this.selectIndex = "ServiceMall";
      }
      this.selectIndex = this.$route.matched[1].name;
    },
    onItemClick: function (item) {
      this.$emit("onChangeFragment", item.componentName);
    },
    // onChangeComponent: function (index) {
    //   this.onItemClick(this.toolBarData[index], index);
    // },
  },
  created(){
  }
};
</script>

<style lang="scss" scoped>
.tool-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 49px;
  display: flex;
  // justify-content: space-around;
  background-color: white;
  box-shadow: 0px -6px 13px 0px rgba(196, 196, 196, 0.2);
  // justify-content: space-between;
  flex: 1;
  z-index: 6;
  .tool-bar-item {
    text-align: center;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .tool-bar-item-img {
      width: 22px;
      height: 22px;
    }
    .tool-bar-item-name {
      font-size: 12px;
      color: #999999;
      margin-top: 4px;
      &-h {
        color: #3783f3;
      }
    }
  }
}
.iphonex-bottom {
  height: calc(49px + 18px);
  padding-bottom: 18px;
}
</style>
